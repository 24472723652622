:root{
    --primary-color : #343a40;
}
.bg-green-light{
    background-color: #28a745!important;
}
.bg-gradient-login{
    background-image: linear-gradient(135deg,rgb(11, 11, 39),rgb(31, 28, 61));
}
.loading-screen{
    content: '';
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
}

.login-container{
    width: 400px;
    min-height: 400px;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar{
    min-width: 250px;
    transition: .4s ease-in-out;
    z-index: 10000;
    left: -250px;
    position: absolute;
    background-color: var(--primary-color);
}
@media(min-width : 992px){
    .sidebar{
        position: relative;
        height: 100%;
        left: 0;
        bottom: 0;
        top: 0;
    }
}
.sidebar-fixed{
    position: fixed;
    width: 250px;
    height: 100%;
}
.show-sidebar{
    left: 0;
}
.title-sidebar{
    height: 62px;
}
.main-navbar ul{
    list-style: none;
}
.menu-item{
    text-align: start;
    transition: .3s ease-in-out;
    cursor: pointer;
}
.menu-item:hover{
    background-color: var(--bs-secondary);
}
.menu-item a.active{
    background-color: var(--bs-primary);
}
.top-navbar{
    height: 62px;
    left: 0px!important;
    background-color: #fff;
}
@media(min-width : 992px){
    .top-navbar{
        left: 250px!important;
    }
}

.shadow{
    position: relative;
}
.shadow::before{
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(56, 56, 56, 0.274);
}
.border-semi-light{
    border-bottom: 1px solid rgb(226, 226, 226);
    box-shadow: -1px -5px 20px 0px rgb(66, 66, 66);
}
.summary-box{
    --box-height : 150px;
    height: var(--box-height);
    border-radius: 0.4rem;
}
.summary-icon-container{
    height: 100%;
    width: fit-content!important;
    right: 10px;
    top: 0;
}
.summary-icon{
    font-size: 60px;
    color: rgba(255, 255, 255, 0.4);
}
.summary-value{
    font-size: 50px;
}
.summary-total{
    background-color: rgba(0, 0, 0, 0.205);
    font-size: 20px;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 0.4rem 0.4rem;
}
.chart{
    height: 350px;
}
.loading{
    position: absolute;
    width: 100%;
    height: 100%;
}