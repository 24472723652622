
html{
  box-sizing: border-box;
}
*,*::before,*::after{
  box-sizing: inherit;
}
body {
  min-height: 100vh;
  font-family: 'Rajdhani', sans-serif;
}

img.img-profile {
  max-width: 270px;
  border: 4px dashed rgb(0, 219, 183);
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}
.w-screen{
  min-height: 100vw;
}
.h-screen{
  min-height: 100vh;
}

/*=========Text typing==========*/
.text-typing {
  padding: 10px 20px 10px 0;
  font-size: 35px;
  font-family: monospace;
  width: fit-content;
}

.text-typing h1 {
  margin: 0px;
  white-space: nowrap;
  overflow: hidden;
  animation: typing 4s steps(22, end) forwards, blink .6s infinite;
}
.paragraph{
  font-family: Arial, Helvetica, sans-serif;
}
@keyframes typing {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes blink {

  0%,
  100% {
    border-right: 2px solid transparent;
  }

  50% {
    border-right: 2px solid rgb(189, 192, 0);
  }
}

/*=========Header==========*/
.banner {
  min-height: 100vh;
  height: 100vh;
  background-color: var(--primary-color);
  color: #fff;
  background-image: url("../images/banner.jpg");

  background-position: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}

@media (max-width: 992px) {
  .banner {
    height: auto;
  }
}

.banner::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.btn-download-cv{
  border-radius: 15px;
  background-color: var(--bs-primary);
  height: 40px;
  padding: 5px 10px;
  font-size: 16px;
}
.btn-download-cv i{
  font-size: 24px;
  margin-left: 3px;
}
.header-navbar {
  height: fit-content;
  width: 100%;
  right: 15px;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  transition: .3s ease-in-out;
  background-color: #08082b96;
}

.header-offset {
  background-color: #000011;
}

.info {
  z-index: 100;
}

.text-custom {
  color: rgb(55, 148, 255) !important;
}

.border-custom {
  border-color: greenyellow !important;
}

.nav-link {
  color: white !important;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 1.1rem;
  transition: .2s ease-in-out;
}

.rounded-custom {
  border-radius: 10px;
}

.active-link {
  color: #fff !important;
  border-bottom: 4px solid #fff;
}

.nav-link:hover {
  border-bottom: 4px solid #fff;
}

.width-dropdown {
  width: 130px;
}

.width-dropdown button {
  width: 100%;
}
.width-dropdown .dropdown-menu{
  min-width: 132px;
  margin-left: -1px;
}

.purple {
  color: rgb(219, 0, 219);
}

.string {
  color: greenyellow;
}

.title-container {
  margin: 2rem 0;
  position: relative;
  border-bottom: 6px solid var(--bs-primary);
  border-left: 6px solid var(--bs-primary);
  width: 80%;
}

.title{
  color: var(--bs-primary);
  text-align: start;
  padding: 0 0 4px 10px;
  position: relative;
  margin: 0;
  z-index: 10;
}

.skill-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-img {
  width: fit-content;
  height: 40px;
  text-align: left;
}

.tech-skills {
  width: fit-content;
  height: 120px;
  text-align: left;
}

.tech-img {
  height: 50px;
  text-align: left;
}

.project.border-bottom:last-of-type {
  border-bottom: none !important;
}

.link {
  text-decoration: none;
  font-weight: 300;
  text-align: center;
  border-radius: 30px;
  transition: .6s ease-in-out;
}

.banner-link {
  display: inline-block !important;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  font-size: 25px;
  text-align: center;
  background-color: white;
  transition: .3s ease-in-out;
  padding: 1.3rem;
  border: 1px solid #fff;
}

.banner-link a {
  display: grid !important;
  place-content: center;
  width: 100%;
  height: 100%;
}

.banner-link i {
  transition: .3s ease-in-out;
  color: #000;
  height: fit-content;
}

.banner-link:hover {
  background-color: transparent;
}

.banner-link:hover i {
  /* background-color: red; */
  /* color: #0a66c2; */
  /* color: #e54033; */
  color: #fff;
}

.footer-link {
  color: #fff;
  display: block;
  font-size: 1rem;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
.fit-content{
  width: fit-content;
}
@media (min-width: 992px) {
  .center-x{
    display: flex;
    justify-content: center;
  }  
}

.footer-link:hover {
  text-decoration: underline;
}

/* .project-links{
    overflow:scroll;
  } */
.project-link {
  max-width: 100%;
  width: 395px;
  text-align: center;
  display: inline-block;
  padding: 0.6rem 1.2rem;
  border-radius: 20px;
  color: white;
  text-decoration: underline;
}

.project-link:hover {
  color: white;
  background-color: greenyellow;
}

.project-link-desc {
  width: 174px
}

.shadow {
  box-shadow: 0 2rem 3rem rgb(0, 0, 0) !important;
}

.gallery {
  cursor: pointer;
  position: relative;
  transition: .4s ease-in-out;
  height: 290px;
  overflow: hidden;
}

.gallery-overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.589);
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  transition: .4s ease-in-out;
}


.gallery-button {
  background-color: white;
  height: fit-content;
  border-radius: 30px;
  font-size: 1rem;
  padding: .4rem 1rem;
  color: #000
}

.text-tech {
  font-weight: 600;
  font-size: 30px;
}

.text-laravel {
  color: #ff2d20;
}

.text-spring {
  color: #77bc1f;
}

.text-java {
  color: #ea2d2e;
  font-weight: bolder;
}

.text-mysql {
  color: #e39410;
}

.text-postgresql {
  color: #336791;
}

.text-django {
  color: #092e20;
}

.text-php {
  color: #222433;
}

.text-bootstrap {
  color: #7912f6;
}

.text-html {
  color: #ff5722;
}

.text-css {
  color: #2196f3;
}

.text-js {
  color: #000000;
}
.text-postman{
  color: #ff6c37;
}
.text-git{
  color: #3e2e00;
}
.text-react{
  color: #000;
}
.bg-footer {
  background-color: rgb(24, 24, 40);
  row-gap: 2rem;
}

.bg-footer-secondary {
  background-color: #000011;
}